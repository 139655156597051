import Layout from "../components/layout"
import React from "react"
import PostCard from "../components/card"
import ScrollAnimation from "react-animate-on-scroll"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import {
  InsightsCol,
  InsightsRow,
  InsightsContainer,
  InsightsContent,
  InsightsWrapper,
  Link,
} from "../styles/insights/style"

import {
  PressClippingWrapper,
  PressClippingContent,
  MagazinContainer,
  Title,
  Subtitle,
  Paragraph1,
  Paragraph2,
  GetInTouchContainer,
  GetInTouchTitle,
  PressClippingImg,
  Email,
} from "../styles/press-clipping/style"

import { Row, Col, Container } from "styled-bootstrap-grid"

const NavigationSlider = withAutoplay(AwesomeSlider)

const PressClipping = () => {
  return (
    <Layout>
      <PressClippingWrapper>
        <PressClippingContent>
          <MagazinContainer>
            <PressClippingImg src="/artboard.png" />
            {/* <Subtitle>EDITOR'S LETTER</Subtitle>
            <Title>'A TOTAL BUNCH OF BANKERS': MICHAEL ALLEN</Title>
            <Row>
              <Col md={6}>
                <Paragraph1>
                  It is time to break one of the RBI house rules. It does not
                  happen very often - say about three times over ten years.
                </Paragraph1>
                <Paragraph2>
                  When I started on this desk more than 15 years ago, I was told
                  among other things: we do not cover people moves, use English
                  English, and avoid book reviews. Such has been the RBI mantra
                  since its first issue in May 1981.
                  <br />
                  During my tenure, people moves remain off limits,
                  notwithstanding the popularity of that feature within sister
                  title Private Banker International. A senior private banking
                  executive jumping ship from, say, Credit Suisse to UBS is good
                  for web traffic. A major US retail bank appointing a new
                  regional mortgage sales executive in rural America - the type
                  of 'people moves' press release we receive - is for the bin.
                  <br />
                  But what about book reviews? I have broken the rule twice.
                  First off was the masterly and very readable Shredded: Inside
                  RBS - the bank that broke Britain. Anyone with a passing
                  interest in banking cannot fail to find that a must-read work
                  by Ian Fraser. The second rule-breaking review related to
                  Vernon Hill's eminently enjoyable Fans not customers - how to
                  create growth companies in a nogrowth world. I was, of course
                  a tad biased, being a fan of Vernon Hill going way back to his
                  Commerce bank days. Suffice to say both reviews were
                  favourable.
                  <br />
                  Bringing off the hat-trick of house rule-breaking reviews is a
                  new release, with the memorable title, A total bunch of
                  bankers. First off, full disclosure: the author, Michael
                  Allen, has been the source for almost all the bank branch
                  stories we have run. The reason for that is simple: so many of
                  the branch stories worth publishing related to branches
                  designed by his firm.
                </Paragraph2>
                <Paragraph1>350 banks, 350.000 branches</Paragraph1>
                <Paragraph2>
                  Allen founded allen international in 1992. He grew the firm to
                  be the world leader in financial services for branding and
                  retail design. In nearly 30 years, he worked with around 350
                  banks, with his design projects responsible for over 300.000
                  branches.
                  <br />
                  The roll call of the more memorable projects is impressive.
                  Allen played a key role in the branch transformation strategy
                  for Emirated NBD. The branches embody the bank's tech-plus
                  ethos, integrating the best of physical with the latest in
                  digital innovation.
                  <br />
                  Tech-plus-touch is also the theme at another major Allen
                  project for Santander. Personal favourites of the writer
                </Paragraph2>
              </Col>
              <Col md={6}>
                <br />
                <br />
                <br />
                <Paragraph2>
                  include other tech focuses hits for Allen, such as its work
                  with Bank of Ireland, Chase and Capitec in South Africa. Then,
                  there is DBS, featured elsewhere in this issue as the latest
                  winners for best branch strategy at RBI's 35th annual global
                  awards. Allen is responsible for the design of one of the most
                  stunning branches anywhere, ever: the DBS flagship in Marina
                  Bay, Singapore.
                  <br />
                  Other highlights include projects incorporating major rebrands
                  such as Ambank in Malaysa, Bank of Beirut in Lebanon, and MCB
                  in Mauritius.
                  <br />
                  Closer to home, allen international bagged the contract to
                  craft the Virgin Money lounges. Not an easy brief, as Virgin
                  needed a design that fitted its maverick identity. At the same
                  time, a good number of Virgin Money's more profitable
                  customers are of a certain age, and unlikely to appreciate
                  loud music or flashing screens.
                  <br />
                  From Itau Unibanco in Brazil, to Bank of China, Absa and ICICI
                  in India, Allen's firm has played a key role reimagining the
                  retail bank. He has had a ringside seat as the branch concept
                  has evolved, putting customers at the heart of everything they
                  do - at least, the successful ones aim to do that.
                </Paragraph2>
                <Paragraph1>Sweet smell of success at Helm</Paragraph1>
                <Paragraph2>
                  As Allen notes, many banks remain rather conservative in their
                  approach. And then there were another personal favourite to report: Helm in Columbia.<br />
                  Back in 2008 - it seems only a few years ago - Michael Allen suggested I might like to make
                  space for Helm's multi-sensory experience. The idea was actually not unique - Nike claims that
                  adding scent to its stores increases intention to purchase. Allen told me that scent marketing
                  had been around since the 1970s. I had not encountered any evidence of this in any bank branch
                  redesign project. The idea did indeed work, with a fruity, zesty aroma finding favour in Bogota.<br />
                  Allen sold allen international to Accenture in 2016. It is no surprise that he now runs his own
                  consulting firm, Michael Allen & Partners.<br />
                  I read the book in one sitting, which is as good a recommendation as I can give. I rather doubt
                  Michael Allen needs the royalities, and to be blunt - however readable - the book will not make
                  the top 10 sellers list.<br />
                  But to anoraks like me, and indeed anyone with an interest in the physical channel, it is hugely
                  enjoyable and I wish it well.
                </Paragraph2>
              </Col>
            </Row>
            <GetInTouchContainer>
              <GetInTouchTitle>
                GET IN TOUCH WITH THE EDITOR AT:
              </GetInTouchTitle>
              <Email>DOUGLAS.BLAKEY@VERDICT.CO.UK</Email>
            </GetInTouchContainer> */}
          </MagazinContainer>
        </PressClippingContent>
      </PressClippingWrapper>
    </Layout>
  )
}
export default PressClipping
